
.dataTables_wrapper {
	position: relative;
	clear: both;
	*zoom: 1;
}

.dataTables_length {
	float: left;
	margin-bottom: 10px;
}

.dataTables_length::after {
	clear: both;
	display: block;
	content: '';
}

.dataTables_length label {
	display: inline-block;
}

.dataTable {
	display: inline-table;
}

.dataTable:before {
	display: block;
	clear: both;
	content: '';
}

.dataTable thead > tr > th {
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 97% center;
}

.dataTables_filter {
	float: right;
	text-align: right;
	margin-bottom: 10px;
}

.dataTables_filter:after {
	display: block;
	clear: both;
	content: '';
}

.dataTables_filter input {
	border: 1px solid #ddd;
	padding: 10px;
	font-zize: 13px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	margin-left: 5px;
}

.dataTables_filter input:focus {
	border-color: #999;
	-moz-box-shadow: 3px 3px 0 rgba(0,0,0,0.05);
	-webkit-box-shadow: 3px 3px 0 rgba(0,0,0,0.05);
	box-shadow: 3px 3px 0 rgba(0,0,0,0.05);
}

.dataTables_info {
	clear: both;
	float: left;
}

.dataTables_paginate {
	float: right;
	text-align: right;
}

/* Two button pagination - previous / next */
.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
	float: left;
	cursor: pointer;
	padding: 6px 15px;
	display: inline-block;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	color: #666;
}

.paginate_enabled_previous,
.paginate_enabled_next {
	color: #fff;
	background: #428BCA;
	border: 1px solid #357EBD;
}

.paginate_enabled_previous:hover,
.paginate_enabled_next:hover {
	color: #fff;
	background: #3276B1;
	border-color: #285E8E;
	color: #fff;
	text-decoration: none;
}

.paginate_disabled_previous,
.paginate_disabled_next {
	border: 1px solid #ccc;
	background-color: #ddd;
	color: #999;
}

.paginate_disabled_previous:hover,
.paginate_disabled_next:hover {
	text-decoration: none;
	color: #999;
	cursor: default;
}

.paginate_disabled_next,
.paginate_enabled_next {
	margin-left: 5px;
}


/* Full number pagination */
.paging_full_numbers {
	line-height: 22px;

}
.paging_full_numbers a:active {
	outline: none
}
.paging_full_numbers a:hover {
	text-decoration: none;
}

.paging_full_numbers a.paginate_button,
.paging_full_numbers a.paginate_active {
	border: 1px solid #ddd;
	padding: 6px 12px;
	margin-right: 5px;
	cursor: pointer;
	color: #636E7B;
	line-height: 21px;
	float: left;
	position: relative;
	background: #fff;
	display: inline-block;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

.paging_full_numbers > li:first-child > a,
.paging_full_numbers > li:first-child > span {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}

.paging_full_numbers a.paginate_button:hover {
	text-decoration: none !important;
	background-color: #E4E7EA;
}

.paging_full_numbers a.paginate_active {
	background-color: #d94d00;
	border-color: #d94d00;
	color: #fff;
}

.paging_full_numbers a:focus {
	text-decoration: none;
}

.paging_full_numbers a.last {
	margin-right: 0;
}

.paginate_button_disabled {
	opacity: 0.5;
}

.paginate_button_disabled:hover {
	cursor: default !important;
	background-color: #fff !important;
}


/*
 * Processing indicator
 */
.dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 30px;
	margin-left: -125px;
	margin-top: -15px;
	padding: 14px 0 2px 0;
	border: 1px solid #ddd;
	text-align: center;
	color: #999;
	font-size: 14px;
	background-color: white;
}


/*
 * Sorting
 */
.sorting {
	background-image: url('../images/sort_both.png');
}

.sorting_asc {
	background-image: url('../images/sort_asc.png');
}

.sorting_desc {
	background-image: url('../images/sort_desc.png');
}

.sorting_asc_disabled {
	background-image: url('../images/sort_asc_disabled.png');
}

.sorting_desc_disabled {
	background-image: url('../images/sort_desc_disabled.png');
}

table.dataTable thead th:active,
table.dataTable thead td:active {
	outline: none;
}


/*
 * Scrolling
 */
.dataTables_scroll {
	clear: both;
}

.dataTables_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch;
}

